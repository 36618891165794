import React, { useEffect, useState } from "react";
import { planetWiseServer, server } from "../../server";
import OtpInput from 'react-otp-input';
import './Register.css';
import { Checkbox } from "@mui/material";
import {Otp} from "../otp/Otp";
import useVH from "react-viewport-height";
import { useNavigate } from "react-router-dom";


export const  Register = (props)=>{
  const vh= useVH();
  let [isMobile,setIsMobile] = useState(window.innerWidth<700);
  let [showOtpScreen,setShowOtpScreen] = useState(false);
  let [userRole,setUserRole]=useState(0);
  let [otp,setOtp] = useState('');
  let [privacy,setPrivacy] = useState(false);
  let [ terms,setTerms] = useState(false);
  let [showPassword,setShowPassword] = useState(false);
  let [userName,setUserName] = useState('');
  let [password,setPassword] = useState('');
  let [errorMessage,setErrorMessage]=useState('');
  const register = async(e)=>{
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    formProps['application']='gm';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify(formProps)
    };
    console.log(formProps);
    const res = await fetch(`${planetWiseServer}/accessmanager/user/v1/register`, requestOptions); 
    const data = await res.json();
    if(data.message && data.error_code){
      setErrorMessage(data.message);
    }else{
      setErrorMessage('');
      setShowOtpScreen(true);
      setUserName(formProps.email);
      setPassword(formProps.password);
      handleSignUpClick(); 
    }
  }

  const resendOtp=async ()=>{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({email:userName||props.username})
    };
    await fetch(`${planetWiseServer}/accessmanager/user/v1/resend-otp`, requestOptions); 
  }

  const submitOtp =async (regOtp)=>{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({otp:regOtp,email:userName||props.username,emailURLVerify:true})
    };
    const res = await fetch(`${planetWiseServer}/accessmanager/user/v1/verify`, requestOptions); 

    const data = await res.json();
    if(data.message && data.error_code){
      setErrorMessage(data.message);
    }else{
      setErrorMessage('');
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:JSON.stringify({email:userName||props.username,password:password||props.password,application:"gm"})
      };
      const res = await fetch(`${planetWiseServer}/accessmanager/user/v1/signin`, requestOptions); 
      const data = await res.json();
      localStorage.setItem('token',data.token);
      localStorage.setItem('userId',data.userProfile.owner);
      navigate("/chat");
    }
  }
  const navigate = useNavigate();


  const handleSignUpClick = () => {
    setShowOtpScreen(true);
    navigate("/verifyotp");
  
    const onOtpScreen = props?. onSignUpClick;
  
    if (onOtpScreen) {
      onOtpScreen(); 
    }
  };

  return <>
    <div style={{ display: "flex",background:"white",paddingBottom:`${2*vh}px`, width: isMobile ? "90vw" : "", flexDirection: "column", margin: "auto", placeItems: "center" }}>
      {!(showOtpScreen || props.showRegistrationOtp) ? 
      <form onSubmit={(e) => register(e)} style={{ display: "flex", flexDirection: "column",width:"100%" }} >
          <div style={{ display: "flex", flexDirection: "column",  marginBottom: "15px" }}>
          <label style={{ marginBottom: "10px" }} for='fullname' >Full name<span style={{color:"red"}}>*</span></label>
          <input required id='fullname' className="pw-login-input" name="fullname" placeholder="Full Name" type="text" /></div>
          <div style={{ display: "flex", flexDirection: "column",  marginBottom: "15px" }}>
          <label style={{ marginBottom: "10px" }} for='email' >Email ID<span style={{color:"red"}}>*</span></label>
          <input required id='email' autoComplete="off" className="pw-login-input" name="email" placeholder="Email Address" type="email" /></div>
          <div style={{ display: "flex", flexDirection: "column",  marginBottom: "15px" }}>
          <label style={{ marginBottom: "10px" }} for='company' >Company name</label>
          <input id='company' className="pw-login-input" name="company" placeholder="Company Name" type="text" /></div>

        <div style={{ display: "flex", flexDirection: "column",  marginBottom: "15px" }}>
          <label style={{ marginBottom: "10px" }} for='password' >Password<span style={{color:"red"}}>*</span></label>
          <div style={{display:"flex",justifyContent:'start',position:'relative'}}>
          <input required id='password' style={{paddingRight:'30px',width:'100%'}} className="pw-login-input" name="password" placeholder="Password" autoComplete="off"
 type={showPassword?"text":"password"} />
          {
            showPassword?  <img onClick={(e)=>{setShowPassword(false)}} src="/hidden_eye.png" className="pw-password-icon"/>:
            <img onClick={(e)=>{setShowPassword(true)}} src="/eye.png" className="pw-password-icon"/>
          }
          </div>
        </div>
        {errorMessage?<span style={{color:"red"}}>{errorMessage}</span>:""}
        <div style={{fontSize:"0.9rem",display:"flex"}}>
        <Checkbox   onChange={(e)=>{setPrivacy(e.target.checked)}} required style={{color:"#59ce90",paddingLeft:'0'}} /> <div>By selecting the checkbox, I confirm that I have read, understood and agree to the <a style={{color:"#59ce90"}} href="https://www.planetwise.io/privacy-policy" target="_blank">Privacy Policy</a></div>
        </div>
        <br/>
        <div style={{fontSize:"0.9rem",display:"flex"}}>
          <Checkbox required onChange={(e)=>{setTerms(e.target.checked)}} style={{color:"#59ce90",paddingLeft:'0'}} />
          <div>By selecting the checkbox, I confirm that I have read, understood and agree to the <a style={{color:"#59ce90"}} href="https://www.planetwise.io/terms-conditions" target="_blank">Terms & Conditions</a></div></div>
          <br/>
        <input className="pw-login-input pw-login-submit" type="submit" value="Sign up" />
      </form> :
        <>
          <div style={{fontSize:'24px',fontWeight:'700',display: "flex",flexDirection: 'row',gap: "50px", borderBottom:"1px solid #d7dfe9",padding:"4px",width:"100%"}}>Welcome {userName ? userName.split("@")[0] : "Admin"} </div>
          <div style={{margin:"1rem",width:"100%", fontSize:"14px",color:"#596981",marginBottom:"2rem"}}>OTP Sent to Registered Mail To Verify Account</div>
          <h3>Enter the OTP</h3>
          <Otp submitOtp={submitOtp} resend={resendOtp}></Otp>
          {errorMessage?<span style={{color:"red"}}>{errorMessage}</span>:""}
   </>}
    </div>
  


  </>
}