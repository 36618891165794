import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import { v4 } from 'uuid';
import { ForgotPassword } from './auth/forgot-password/ForgotPassword';
import {Chat} from './chat/Chat';

import { Auth } from './auth/Auth';
import { useEffect } from 'react';

function App() {
  useEffect(()=>{
    if(window.location.href.split('?').length==2){
      let params  = new URLSearchParams(window.location.href.split('?')[1]);
      if(params.get('token').length){
        localStorage.setItem('token',params.get('token'));
      }
      if(params.get('userId').length){
        localStorage.setItem('userId',params.get('userId'));
      }
      window.location = window.location.origin;
    }
    if(!localStorage.getItem("token")){
      if(!window.location.pathname.includes("login")){
      window.location.pathname = '/login';
      }
    }else{
      if(!window.location.pathname.includes("chat")){
      window.location.pathname = '/chat';
    }
    }
  },[])

  return (
      <Router>
        <Routes>
          <Route
            path="/"
          >
            {/* <Route index element={
                  <Navigate replace to={"/chat"} />
            }/> */}
            <Route path="login" element={<Auth/>} />
            <Route path='chat' element={<Chat />}/>
            <Route path='register' element={<Auth/>} />
            <Route path="forgotpassword" element={<Auth />} />
            <Route path="verifyotp" element={<Auth />} />
            <Route path="verifypassword" element={<ForgotPassword />} />
            <Route path="resetpassword" element={<Auth />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      </Router>
  );
}

export default App;
