import React, { useEffect, useState } from "react";
import { planetWiseServer, server } from "../../server";
import OtpInput from 'react-otp-input';
import './Login.css';
import { ForgotPassword } from "../forgot-password/ForgotPassword";
import useVH from "react-viewport-height";
import SessionsListView from "./SessionsListView";
import { useNavigate } from "react-router-dom";


export const  Login = (props)=>{


  const vh=  useVH();
  let [isMobile,setIsMobile] = useState(window.innerWidth<700);
  let [showOtpScreen,setShowOtpScreen] = useState(false);
  let [requiredAuth,setRequiredAuth] = useState('');
  let [userRole,setUserRole]=useState(0);
  let [otp,setOtp] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  let [showPassword,setShowPassword] = useState(false);
  let [userName,setUserName] = useState('');
  let [errorMessage,setErrorMessage]=useState('');

  const [showSessionsListView, setShowSessionsListView] = useState(false);
  const [email, setEmail] = useState('');
  const [temporaryToken, setTemporaryToken] = useState(''); 

  const onCloseSessionListView = () => {
    setShowSessionsListView(false);
    window.location.reload();
  };

  const signIn = async(e)=>{
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    formProps['application']="gm";
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify(formProps)
    };
    const res = await fetch(`${planetWiseServer}/accessmanager/user/v1/signin`, requestOptions); 
    const data = await res.json();
    if(data.message && data.error_code){
      setErrorMessage(data.message);
    }else{
      setErrorMessage('');
    }
    if(data?.token?.length) {
      const sessionId = JSON.parse(atob(data.token?.split(".")[1]))?.iss?.sessionId;
      if(!sessionId) {
        setEmail(formProps.email.toLocaleLowerCase());
        setTemporaryToken(data.token);
        setShowSessionsListView(true);
        return;
      }
    }
    props.setUsername(formProps.email);
    props.setPassword(formProps.password);
    setUserDataAndToken(data);
  }
  const navigate = useNavigate();

  const setUserDataAndToken= (data)=>{
    if(data?.userProfile?.owner){
      localStorage.setItem('userId',data.userProfile.owner);
    }
    if(data.token){
      localStorage.setItem('token',data.token)
      navigate("/chat");
    }else if(data.appAuthRequired || data.emailAuthRequired){
      setShowOtpScreen(true);
      setRequiredAuth(data.appAuthRequired?"appAuth":"emailAuth");
      setUserRole(data.role);
      setUserName(data.userProfile.email);
    }else if(data.message.startsWith('otp')){
      props.setShowRegistrationOtp();
    }
  }
  const submitOtp =async ()=>{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({otp:otp,role:userRole,type:requiredAuth,generateToken:true,username:userName})
    };
    const res = await fetch(`${planetWiseServer}/accessmanager/user/v1/verify-2fa-auth-otp`, requestOptions); 
    if(res.status==400){
      localStorage.removeItem('token');
      window.location.reload();
    }
    const data = await res.json();
    if(data.message){
      setErrorMessage(data.message);
    }else{
      setErrorMessage('');
    }
    if(data?.token?.length) {
      const sessionId = JSON.parse(atob(data.token?.split(".")[1]))?.iss?.sessionId;
      if(!sessionId) {
        setEmail(userName.toLocaleLowerCase());
        setTemporaryToken(data.token);
        setShowSessionsListView(true);
        return;
      }
    }
    setUserDataAndToken(data);
  }

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
    navigate('/forgotpassword')
  
    const onForgotPassword = props?.onForgotPasswordClick;
  
    if (onForgotPassword) {
      onForgotPassword(); 
    }
  };

    
  return <>
    <div style={{ display: "flex",background:'white', width: isMobile ? "90vw" : "", flexDirection: "column", margin: "auto",paddingBottom:`${vh*2}px`, placeItems: "center" }}>
      { !showForgotPassword? 
      !showOtpScreen ? 
      <form onSubmit={(e) => signIn(e)} style={{ display: "flex", flexDirection: "column",width:"100%",}} >
        <div style={{ display: "flex", flexDirection: "column",  marginBottom: "15px" }}>
          <label style={{ marginBottom: "10px" }} for='email' >Email ID<span style={{color:"red"}}>*</span></label>
          <input required id='email' className="pw-login-input" name="email" placeholder="Email Address" type="email" /></div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ marginBottom: "10px" }} for='password' >Password<span style={{color:"red"}}>*</span></label>
          <div style={{display:"flex",justifyContent:'start',position:'relative'}}>
          <input required id='password' style={{paddingRight:'30px',width:'100%'}} className="pw-login-input" name="password" placeholder="Password" type={showPassword?"text":"password"} />
          {
            showPassword?  <img onClick={(e)=>{setShowPassword(false)}} src="/hidden_eye.png" className="pw-password-icon"/>:
            <img onClick={(e)=>{setShowPassword(true)}} src="/eye.png" className="pw-password-icon"/>
          }
          </div>
        </div>
        <div onClick={() => handleForgotPasswordClick()}  style={{ display: 'flex', cursor: "pointer", justifyContent: "end", marginBottom: "15px", fontSize: "0.9rem", color: "#59ce90" }}>
      Forgot Password
    </div>
        {errorMessage?<span style={{color:"red"}}>{errorMessage}</span>:""}
        <input className="pw-login-input pw-login-submit" type="submit" value="Login" />
      </form> :
        <>
          <h3>Enter the OTP</h3>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputStyle="pw-login-input"
            renderSeparator={<span style={{display:"inline-block",position:'relative',top:"-5px"}} >&nbsp;-&nbsp;</span>}
            renderInput={(props) => <input  {...props} input />}
          />
          {errorMessage?<span style={{color:"red"}}>{errorMessage}</span>:""}
          <button className="pw-login-input pw-login-submit" style={{marginTop:"20px"}} onClick={(e)=>{submitOtp()}} >Login</button>
           </>:<ForgotPassword></ForgotPassword>
}
    </div>
    {showSessionsListView && (<SessionsListView
      isDialog={true}
      open={showSessionsListView}
      onClose={onCloseSessionListView}
      username={email}
      temporaryToken={temporaryToken}
    />)}
  </>
}

export default Login
